<template>
  <div class="home container">
    <div class="banner">
      <div class="columns">
        <div class="column is-3 is-12-mobile text-block">
          <h1 class="home-text-block--title">“The more you know, the less you have to carry.”<br><small>- Mors Kochanski</small></h1>

          <p class="text-block--text">{{ $t(`home.description`) }}</p>

          <router-link :to="{path: '/courses'}"><button class="loup-button"><b>{{ $t(`home.button`) }}</b></button></router-link>
        </div>
        <div class="column is-7 is-12-mobile is-offset-2 banner-img">
          <img src="../assets/shapes/home-illustration.svg" :alt="$t(`home.img.illustration`)">
        </div>
      </div>

      <div class="scroll is-hidden-mobile">
        <span>Scroll</span>
        <img src="../assets/shapes/arrow-down.svg" :alt="$t(`home.img.scroll`)" class="scroll--arrow">
      </div>
    </div>

    <div class="columns courses-wrapper">
      <div class="column is-7 is-12-mobile courses-list">
        <div class="courses-list--item is-12">
          <img src="../assets/shapes/course-splash.svg" class="course-splash" alt="">
          <div class="courses-list--item--text columns">
              <div class="column is-4 is-offset-2 is-hidden-mobile course-list--item-title" v-html="$t(`expeditions.basic`)"></div>
              <div class="column is-4 is-offset-2 is-11-mobile is-hidden-desktop is-hidden-tablet course-list--item-title" v-html="$t(`expeditions.basicMobile`)"></div>
              <div class="column is-5 is-12-mobile item-text">
                <p>{{ $t(`home.expeditions.basic.description`) }}</p>
                <div class="course-list--item-button-group">
                  <router-link :to="{path: '/signup', query: {course: 'basic'}}"><button class="loup-button--filled"><b>{{ $t(`general.join`) }}</b></button></router-link>
                  <router-link :to="{path: '/courses'}"><button class="loup-button--outline"><b>{{ $t(`general.info`) }}</b></button></router-link>
                </div>
              </div>
          </div>
        </div>
        <div class="courses-list--item is-12">
          <img src="../assets/shapes/course-splash-green.svg" class="course-splash" alt="">
          <div class="courses-list--item--text columns">
            <div class="column is-4 is-offset-2 is-hidden-mobile course-list--item-title" v-html="$t(`expeditions.advanced`)"></div>
            <div class="column is-4 is-offset-2 is-11-mobile is-hidden-desktop is-hidden-tablet course-list--item-title" v-html="$t(`expeditions.advancedMobile`)"></div>
            <div class="column is-5 is-12-mobile item-text">
              <p>{{ $t(`home.expeditions.advanced.description`) }}</p>
              <div class="course-list--item-button-group">
                <router-link :to="{path: '/signup', query: {course: 'advanced'}}"><button class="loup-button--filled"><b>{{ $t(`general.join`) }}</b></button></router-link>
                <router-link :to="{path: '/courses'}"><button class="loup-button--outline"><b>{{ $t(`general.info`) }}</b></button></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 is-offset-1 is-12-mobile is-offset-0-mobile text-block">
        <h1 class="text-block--title" v-html="$t(`home.expeditions.textBlock.title`)"></h1>

        <p class="text-block--text">{{ $t(`home.expeditions.textBlock.text`) }}</p>

        <router-link :to="{path: '/signup'}" :aria-label="$t(`labels.signup`)"><button class="loup-button is-hidden-mobile"><b>{{ $t(`general.join`) }}</b></button></router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home',
}
</script>
<style lang="scss" scoped>
  .home-text-block--title {
      font-size: 2vmax;
      font-family: 'Arima Madurai', cursive;
      font-weight: 900;
      color: #01504E;

    @media screen and(max-width: 769px) {
      font-size: 16px;
    }

    small {
      font-size: 1vmax;

      @media screen and(max-width: 769px) {
        font-size: 12px;
      }
    }
  }

  .text-block {
    align-self: center;

    .loup-button {
      margin-top: 1.5rem;
    }
  }

  .banner .text-block {
    align-self: flex-end;

    .loup-button {
      margin-bottom: .5rem;
    }
  }

  .banner {
    min-height: 82vh;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and(max-width: 769px) {
      min-height:unset;
    }

    .columns {
      display: flex;
      align-items: center;
    }

    @media screen and(max-width: 769px) {
      .columns {
        display: unset;
        align-items: unset;
      }
    }
  }

  .banner-img {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
      max-height: 60vh;

      @media screen and(max-width: 769px) {
        height: auto;
        margin-bottom: 3rem;
      }
    }
  }

  .scroll {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    bottom: 10vh;
    left: 50%;

    .scroll--arrow {
      margin-top: 1rem;
      animation: bounce 2s infinite;
    }

    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      40% {
        -moz-transform: translateY(2vh);
        -ms-transform: translateY(2vh);
        -webkit-transform: translateY(2vh);
        transform: translateY(2vh);
      }
      60% {
        -moz-transform: translateY(1vh);
        -ms-transform: translateY(1vh);
        -webkit-transform: translateY(1vh);
        transform: translateY(1vh);
      }
    }
  }

  .splash-blue {
    background-image: url("../assets/shapes/course-splash.svg");
  }
  .splash-light {
    background-image: url("../assets/shapes/course-splash-green.svg");
  }

  .courses-wrapper {
    flex-direction: row;

    @media screen and(max-width: 769px) {
      flex-direction: column;

      .text-block {
        order: -1;
      }
    }
  }

  .course-splash {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -10;
  }

  .courses-list--item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 2rem 0;

    &:not(:first-child) {
      margin-top: 2.5rem;

      @media screen and(max-width: 769px) {
        margin-top: 1rem
      }
    }

    .course-list--item-title {
      font-family: 'Arima Madurai', cursive;
      font-weight: 900;
      font-size: 14pt;
      line-height: 49px;
      display: flex;
      align-items: center;
    }

    .courses-list--item--text {

      .item-text {
        font-family: 'Avenir', sans-serif;
        font-weight: 500;
        font-size: .7vmax;
        line-height: 25px;
      }

      .course-list--item-button-group {
        margin-top: 1rem;

        a:last-child button {
          margin-left: 1vw;
        }
      }
    }
  }

  @media screen and(max-width: 1024px) {
    .banner {

      .loup-button {
        z-index: 2;
        position: absolute;
      }

      .banner-img {
        img {
          margin-top: 2vh;
        }
      }
    }

    .courses-wrapper {
      display: flex;
      margin-bottom: 2rem !important;
    }

    .courses-list--item {
      background-repeat: no-repeat;
      background-size: contain;
      align-items: unset;
      min-height: unset;

      .course-list--item-title {
        font-size: 2vmax;
        line-height: 36px;
        display: flex;
        align-items: center;
        padding-bottom: 0;
      }

      .courses-list--item--text {

        .item-text {
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }

        .course-list--item-button-group {
          margin-top: 1rem;

          a:last-child button {
            margin-left: 1rem;
          }
        }
      }
    }
  }

</style>
