import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { i18n } from "./plugins/i18n";
import './plugins/buefy'
import MomentPlugin from './plugins/moment';
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(MomentPlugin, '$moment');
Vue.use(VueReCaptcha, {siteKey: '6LeVP8UUAAAAAPloMs4oxOpAgZPNHdiLpDvdYNl9'});

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
