import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
export const i18n = new VueI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages: {
        nl: {
            navbar: {
                home: 'Home',
                expeditions: 'Cursussen',
                material: 'Materiaal',
                about: 'Over',
                contact: 'Contact',
                join: 'Deelnemen',
                img: {
                    logo: 'Logo van Loup Solitair',
                    hamburger: 'Navigatie menu',
                    nl: 'Wissel naar Nederlands',
                    en: 'Change to English'
                }
            },
            home: {
                description: 'Welkom bij Loup Solitaire. De plek voor mensen die het een uitdaging vinden om te leren overleven in en met de natuur. Wij geven diverse survival cursussen in de Ardennen voor iedereen. Bekijk ons aanbod en meld je aan!',
                expeditions: {
                    textBlock: {
                        title: 'Voor Iedereen',
                        text: 'Onze survival cursussen zijn voor iedereen toegankelijk. Wij delen graag onze kennis en ervaringen. Ben je tijdens de cursus op zoek naar meer of juist iets minder uitdaging? Wij zijn daarin flexibel en passen ons daarop aan.'
                    },
                    basic: {
                        description: 'Heb je nog geen tot weinig kennis over overleven in de natuur, maar vind je het interessant en wil je hier meer over leren? Dan sluit deze 3-daagse cursus in de Ardennen goed aan bij jou.'
                    },
                    advanced: {
                        description: 'Heb je al aardig wat survival ervaring in je rugzak zitten en wil je je kennis en skills nog verder ontwikkelen of heb je onze basiscursus al gevolgd? Dan past deze 4-daagse cursus in de Ardennen perfect bij jou.'
                    }
                },
                img: {
                    illustration: 'Illustratie van een bos',
                    scroll: 'Scroll naar beneden'
                },
                button: 'Aanbod bekijken'
            },
            general: {
                join: 'Aanmelden',
                info: 'Meer info'
            },
            expeditions: {
                textBlock: {
                    title: 'Voor Iedereen',
                    text: 'Onze survival cursussen zijn voor iedereen toegankelijk. Wij delen graag onze kennis en ervaringen. Ben je tijdens de cursus op zoek naar meer of juist iets minder uitdaging? Wij zijn daarin flexibel en passen ons daarop aan.'
                },
                basic: 'Survival cursus<br>voor beginners',
                basicMobile: 'Survival cursus voor beginners',
                advanced: 'Survival cursus<br>voor gevorderden',
                advancedMobile: 'Survival cursus voor gevorderden',
                descriptions: {
                    basic: 'Heb je nog geen tot weinig kennis over overleven in de natuur, maar vind je het interessant en wil je hier meer over leren? Dan sluit deze 3-daagse cursus in de Ardennen goed aan bij jou.<br><br>' +
                        'Tijdens deze cursus leer je onder andere welke instelling je nodig hebt om te overleven, hoe je onderkomens maakt van de uitrusting uit je rugzak en van natuurlijk materialen.<br><br>' +
                        'Je leert hoe je water en voedsel uit de natuur kunt halen en hoe je dit op een veilige manier kunt bereiden. Je leert vuur maken met verschillende methodes. We zullen je ook kennis laten maken met planten die je kunt eten en welke planten geneeskrachtig zijn. Je leert de basis van het navigeren met kaart en kompas.<br><br>' +
                        'Als je denkt dat dit iets voor jou is, meld je dan nu aan! De prijs van de cursus staat hieronder genoemd. Deze dient voorafgaand aan de cursus betaald te worden. Na aanmelding nemen we contact op voor de betalingsinstructies.',
                    basic1: 'Heb je nog geen tot weinig kennis over overleven in de natuur, maar vind je het interessant en wil je hier meer over leren? Dan sluit deze 3-daagse cursus in de Ardennen goed aan bij jou.',
                    basic2: 'Tijdens deze cursus leer je onder andere welke instelling je nodig hebt om te overleven, hoe je onderkomens maakt van de uitrusting uit je rugzak en van natuurlijk materialen.<br><br>' +
                        'Je leert hoe je water en voedsel uit de natuur kunt halen en hoe je dit op een veilige manier kunt bereiden. Je leert vuur maken met verschillende methodes. We zullen je ook kennis laten maken met planten die je kunt eten en welke planten geneeskrachtig zijn. En je leert de basis van het navigeren met kaart en kompas.',
                    basic3: 'Als je denkt dat dit iets voor jou is, meld je dan nu aan! De prijs van de cursus staat hieronder genoemd. Deze dient voorafgaand aan de cursus betaald te worden. Na aanmelding nemen we contact op voor de betalingsinstructies.',
                    advanced: 'Heb je al aardig wat survival ervaring in je rugzak zitten en wil je je kennis en skills nog verder ontwikkelen of heb je onze basiscursus al gevolgd? Dan past deze 4-daagse cursus in de Ardennen perfect bij jou.<br><br>' +
                        'Deze cursus wordt alleen in de maanden december tot en met maart gegeven. Tijdens deze cursus leer je hoe je moet overleven in winterse omstandigheden en welke kleding daarbij van belang is. Je leert een warm en waterdicht onderkomen te maken van je eigen uitrusting maar ook hoe je een warm en waterdicht onderkomen van natuurlijke materialen maakt. En je leert vuur maken in vochtige en koude omstandigheden.<br><br>' +
                        'Als je denkt dat dit iets voor jou is, meld je dan nu aan! De prijs van de cursus staat hieronder genoemd. Deze dient voorafgaand aan de cursus betaald te worden. Na aanmelding nemen we contact op voor de betalingsinstructies.',
                    advanced1: 'Heb je al aardig wat survival ervaring in je rugzak zitten en wil je je kennis en skills nog verder ontwikkelen of heb je onze basiscursus al gevolgd? Dan past deze 4-daagse cursus in de Ardennen perfect bij jou.',
                    advanced2: 'Deze cursus wordt alleen in de maanden december tot en met maart gegeven. Tijdens deze cursus leer je hoe je moet overleven in winterse omstandigheden en welke kleding daarbij van belang is. Je leert een warm en waterdicht onderkomen te maken van je eigen uitrusting maar ook hoe je een warm en waterdicht onderkomen van natuurlijke materialen maakt.<br><br>',
                    advanced3: 'Als je denkt dat dit iets voor jou is, meld je dan nu aan! De prijs van de cursus staat hieronder genoemd. Deze dient voorafgaand aan de cursus betaald te worden. Na aanmelding nemen we contact op voor de betalingsinstructies.',
                },
                prices: {
                    basic: '&euro; 195 p.p.',
                    advanced: '&euro; 325 p.p.'
                },
                img: {
                    basic1: 'Mensen op cursus lopend door een weiland',
                    basic2: 'Eten koken boven een zelfgemaakt vuur',
                    advanced1: 'Richtingbepaling met kompas in een nat bos',
                    advanced2: 'Route bepalen op de kaart',
                }
            },
            contact: {
                textBlock: {
                    title: 'Vraag Gerust',
                    text: 'Heb je vragen over &#233;&#233;n van de cursussen of heb je een ander bericht? Laat het ons weten via het formulier en we nemen zo snel mogelijk contact met je op!'
                },
                form: {
                    name: 'Naam',
                    email: 'E-mailadres',
                    phone: 'Telefoonnummer',
                    message: 'Bericht',
                    send: 'Versturen',
                    success: 'Bedankt voor het invullen. Mocht het nodig zijn zullen we contact met je opnemen.',
                    sending: 'Versturen...',
                    phoneEmailError: 'Vul alsjeblieft je e-mailadres of telefoonnummer in zodat we contact op kunnen nemen.'
                }
            },
            about: {
                textBlock: {
                    title: 'Even Voorstellen',
                    text: 'Hallo! Mijn naam is Dave Scherphof en ik leid de survival cursussen met passie voor mijn vak. Ik wil mijn kennis en ervaringen graag met jullie delen!'
                },
                button: 'Cursussen',
                img: {
                    photo: 'Foto van Dave, lezend in een boek over planten'
                },
                1: 'Mijn naam is Dave Scherphof en ik ben de oprichter van Loup Solitaire.  Van kinds af aan heeft de natuur mij enorm gefascineerd en ik denk dat ieder mens dit ergens in zich heeft. Onze basis ligt namelijk in de natuur. Tegenwoordig is het met al onze gemakken maar ook met onze drukke levens, lastig te bedenken dat de mens ooit volledig in de natuur heeft geleefd. De natuur is nog steeds hetzelfde, maar de mens is veranderd. Onze kennis om te kunnen overleven in de natuur is deels verloren gegaan. Gelukkig zijn er nog volken die deze kennis van generatie op generatie hebben doorgegeven. Ik mag mijzelf gelukkig prijzen dat ik een deel van deze kennis heb mogen leren.',
                2: 'Als kind droomde ik ervan om in een hut midden in het bos te leven. Vol bewondering keek ik naar de programma\'s van Ray Mears en dacht ik: Hoe word je survival expert? Was daar maar een school voor, dan weet ik wel welke studie ik wil doen. Om bij de natuur in de buurt te komen ben ik militair geworden. Ook de actie hierin sprak me aan.<br><br>' +
                    'Na de eerste militaire jaren een logistieke functie vervuld te hebben ben ik verder gaan kijken naar de mogelijkheden. Met veel geluk en doorzettingsvermogen ben ik bij een klein team terecht gekomen dat gespecialiseerd was in het uitvoeren van helikopter operaties. Dat was een geweldige baan. Voor dit werk moest ik survivalopleidingen volgen bij de SERE-school. Hier werd ik meegevoerd in de survivalwereld. Toen al dacht ik "wat nu als ik hier toch eens m\'n werk van kan maken, maar dan als zelfstandig ondernemer, op de manier hoe ik dat graag wil."',
                3: 'Een jaar later en heel veel beslissingen verder heb ik het roer omgegooid. Ik heb ontslag genomen en ben terug naar school gegaan. Die school waar ik eerder van droomde bestond nu; "De Voshaar"! September 2015 begon ik aan mijn opleiding. In Maart 2019 en heel veel ervaringen en kennis rijker, ontving ik mijn diploma als internationaal wildernis gids.<br><br>' +
                    'En nu wil ik mijn kennis, ervaringen en passie met jullie delen!',
                theName: 'De naam Loup Solitaire',
                aboutTheName: 'Loup Solitaire of in het Nederlands "eenzame wolf."<br><br> Ik was op 24 uurs solo-survivaltocht in de wildernis van de Canadese Rocky Mountains tijdens mijn Canadian survival en woodstravel instructor cursus aan "de Voshaar". Vroeg in de ochtend was ik mijn kamp aan het opruimen. Het was net licht. Ik stond vlak aan een riviertje met aan de andere kant van het water een flinke heuvel. We waren de weken ervoor al veel wilde dieren tegengekomen in het gebied, maar wat ik op dat moment meemaakte had ik alleen durven dromen. Terwijl ik mijn slaapzak in mijn tas stopte hoorde ik achter de heuvel een wolf huilen. Ik huilde een keer terug naar de wolf, het was even stil en toen huilde de wolf weer. Was dit toeval of een reactie op mijn huil? Nog een keer, een diepe huil zo goed als ik een wolvenhuil na kon doen. Ik was nog niet klaar of kreeg al een reactie. Geweldig! Kippenvel! Dit ging een minuut of twintig zo door en ik hoorde de wolf steeds dichterbij komen. Ineens was het klaar, ik hoorde niks meer. Jammer dacht ik, maar toch zo blij met deze ervaring. <br><br>Tijdens het inpakken van mijn tas en opbreken van het kamp hoorde ik aan de overkant van het water een gepiep zoals hondachtigen dat kunnen doen. Dit kan niet waar zijn, dacht ik. Ik rende richting de rivier maar bedacht me al na een paar passen dat ik rustig moest lopen om de wolf niet te laten schrikken. Vol spanning liep ik langzaam de laatste meters door het bos naar de rivier en daar stond hij, in zijn eentje, een meter of vijftig bij mij vandaan, aan de overkant van het water. Hij stak z\'n neus in de lucht en bewoog z\'n kop even op en neer. Hij piepte nog &#233;&#233;n keer, draaide zich om en verdween de heuvel op het bos in. Bijkomend van deze allermooiste ervaring hoorde ik na vijf minuten de wolf weer huilen. Ik reageerde en hij ook, maar deze keer werd de afstand groter tussen ons. Tot ik niks meer hoorde.' +
                    '<br><br><b>Loup solitaire.</b>'
            },
            signup: {
                textBlock: {
                    title: 'Doe Mee!',
                    text: 'Spreekt &#233;&#233;n van onze cursussen jou aan? Geef je dan snel op. Je kunt je ook met meer personen inschrijven, zet dan even in het opmerkingenveld met wie en vermeld zijn of haar e-mailadres en telefoonnummer. Tot snel!'
                },
                basic: 'Survival cursus voor beginners',
                advanced: 'Survival cursus voor gevorderden',
                child: 'Ouder & kind survival weekend',
                till: 't/m',
                form: {
                    title: 'Aanmeldformulier',
                    name: 'Naam',
                    email: 'E-mailadres',
                    phone: 'Telefoonnummer',
                    course: 'Cursus:',
                    available: 'Welke data ben je beschikbaar:',
                    comments: 'Opmerkingen',
                    send: 'Versturen',
                    noCourse: 'Selecteer een cursus',
                    success: 'Bedankt voor het aanmelden, we nemen binnenkort contact met je op.',
                    sending: 'Versturen...',
                    nodate1: 'Mocht geen van de datums je uitkomen, neem even',
                    nodate2: 'met ons op. We zijn erg flexibel!',
                    phoneEmailError: 'Vul alsjeblieft je e-mailadres of telefoonnummer in zodat we contact op kunnen nemen.'
                }
            },
            error: {
                send: "Sorry er ging iets mis probeer het later opnieuw",
            },
            footer: {
                phone: "Tel"
            },
            labels: {
                signup: "Aanmelden"
            }
        },
        en: {
            navbar: {
                home: 'Home',
                expeditions: 'Courses',
                about: 'About',
                contact: 'Contact',
                join: 'Sign up',
                img: {
                    logo: 'Logo of Loup Solitair',
                    hamburger: 'Navigation menu',
                    nl: 'Wissel naar Nederlands',
                    en: 'Change to English'
                }
            },
            home: {
                description: 'Welcome to Loup Solitaire! This is the place for people who love to learn how to survive in nature. We provide a selection of survival courses in the Ardennes to suit every level of experience. Take a look at our different programs and sign up!',
                expeditions: {
                    textBlock: {
                        title: 'For everyone',
                        text: 'Our survival courses are open to everyone. Are you looking for a more or little less challenging experience? We are flexible and love to tailor to your needs. Please let us know!'
                    },
                    basic: {
                        description: 'Do you have little or no  survival skills, but want to learn the basics? Then this 3-day course in the Ardennes is a great introduction.'
                    },
                    advanced: {
                        description: 'Do you already have survival practice under your belt and would love to bring your knowledge and skills to the next level? Or have you followed our survival course for beginners? Then this 4-day course in the Ardennes is perfect for you.'
                    }
                },
                img: {
                    illustration: 'Illustration of the Ardennes',
                    scroll: 'Scroll down'
                },
                button: 'View courses'
            },
            general: {
                join: 'Sign up',
                info: 'More info'
            },
            expeditions: {
                textBlock: {
                    title: 'For everyone',
                    text: 'Our survival courses are open to everyone. Are you looking for a more or little less challenging experience? We are flexible and love to tailor to your needs. Please let us know!'
                },
                basic: 'Beginners Survival<br>Course',
                basicMobile: 'Beginners Survival Course',
                advanced: 'Advanced Survival<br>Course',
                advancedMobile: 'Advanced Survival Course',
                descriptions: {
                    basic: 'Little or no nature survival skills but want to learn the basics? Then this 3-day course in the Ardennes is a great introduction.<br><br>' +
                        'During this course you will learn to adopt the mindset required to survive in the wild. You will learn to make a shelter from the equipment in your backpack and what you can find in the forest.<br><br>' +
                        'You will forage for water and food in the wild and learn how to prepare it safely. We teach you how to spot edible and medicinal plants. You’ll learn different methods to make fire for cooking and heat. And you will also learn the basics of navigating with map and compass.<br><br>' +
                        'If this is for you, sign up now! You can find the price of the Beginners Survival Course below. Payment must be made prior to the course. After registration we will contact you with further instructions.',
                    basic1: 'Little or no nature survival skills but want to learn the basics? Then this 3-day course in the Ardennes is a great introduction.',
                    basic2: 'During this course you will learn to adopt the mindset required to survive in the wild. You will learn to make a shelter from the equipment in your backpack and what you can find in the forest.<br><br>' +
                        'You will forage for water and food in the wild and learn how to prepare it safely. We teach you how to spot edible and medicinal plants. You’ll learn different methods to make fire for cooking and heat. And you will also learn the basics of navigating with map and compass.',
                    basic3: 'If this is for you, sign up now! You can find the price of the Beginners Survival Course below. Payment must be made prior to the course. After registration we will contact you with further instructions.',
                    advanced: 'Already have survival practise under your belt and want to bring your knowledge and skills to the next level? Or have you completed our Beginners Survival Course? Then this 4-day course in the Ardennes is perfect for you.<br><br>' +
                        'This course is available  from December through March. You will learn how to survive in winter conditions and clothe yourself appropriately. You learn how to make a warm and waterproof shelter from your own equipment by utilising materials straight from nature. You’ll learn to make a fire in damp and cold conditions.<br><br>' +
                        'If this is for you, sign up now! You can find the price of the Advanced Survival Course below. Payment must be made prior to the course. After registration we will contact you with further instructions.',
                    advanced1: 'Already have survival practise under your belt and want to bring your knowledge and skills to the next level? Or have you completed our Beginners Survival Course? Then this 4-day course in the Ardennes is perfect for you.',
                    advanced2: 'This course is available  from December through March. You will learn how to survive in winter conditions and clothe yourself appropriately. You learn how to make a warm and waterproof shelter from your own equipment by utilising materials straight from nature. You’ll learn to make a fire in damp and cold conditions.',
                    advanced3: 'If this is for you, sign up now! You can find the price of the Advanced Survival Course below. Payment must be made prior to the course. After registration we will contact you with further instructions.',
                },
                prices: {
                    basic: '&euro; 195 p.p.',
                    advanced: '&euro; 325 p.p.'
                },
                img: {
                    basic1: 'Mensen op cursus lopend door een weiland',
                    basic2: 'Eten koken boven een zelfgemaakt vuur',
                    advanced1: 'Richtingbepaling met kompas in een nat bos',
                    advanced2: 'Route bepalen op de kaart',
                }
            },
            contact: {
                textBlock: {
                    title: 'Please ask',
                    text: 'Questions about the courses or would you like to make another inquiry? Please let us know through the online form and we will contact you as soon as possible!'
                },
                form: {
                    name: 'Name',
                    email: 'Email address',
                    phone: 'Phone number',
                    message: 'Message',
                    send: 'Send',
                    success: 'Thank you for filling out. If necessary we will contact you.',
                    sending: 'Sending...',
                    phoneEmailError: 'Please enter your email address or phone number, so we can contact you.'
                }
            },
            about: {
                textBlock: {
                    title: 'Introduce Myself',
                    text: 'Hello! My name is Dave Scherphof and I run survival courses out of love for my profession. Let me share my knowledge and experiences with you!'
                },
                button: 'Courses',
                img: {
                    photo: 'Photo of Dave, standing in a snowy forest'
                },
                1: 'My name is Dave Scherphof and I am the founder of “Loup Solitaire”. From a young age I’ve been fascinated by nature, a fascination which I firmly believe is in all of us. Today with all our conveniences and busy lives it’s getting harder to imagine that we used to live and thrive in the outdoors. Our knowhow on how to survive in nature has therefore been partially lost. Fortunately there are still peoples (tribes) who have been passing on information from generation to generation. I feel fortunate that I’ve been able to take a part of this knowledge to heart.',
                2: 'As a child I dreamed of living in a cabin in the forest. Full of bewilderment I used to watch episodes of Ray Mears and thought: “how can I become a survival expert?” If there was a school for that, I wouldn’t think twice! To get closer to nature I decided to become a soldier. The action part also appealed to me.<br><br>' +
                 'After having fulfilled a position in logistics during the first years in the military, I wanted to \n' +
                    'broaden my horizons. With luck and perseverance, I ended up in a small team that specialised in conducting helicopter missions. I loved that job, as for this line of work I had to follow survival training at the SERE school. Here I was taken deep into the world of survival. \n' +
                    'Even then I thought "what if I could create a company around this and incorporate my own ideas?”.',
                3: 'A year later I decided it was time for change. I quit my job and went back to school. The school I used to dream about now actually existed “De Voshaar”! September 2015 I started the course. In March 2019 with a lot of new knowledge and experience under my belt I received my diploma as an international wilderness guide. Now I share that knowledge and experience with you!',
                theName: 'Origin of the name Loup Solitaire',
                aboutTheName: 'Loup Solitaire means "lonely wolf” in English.<br><br>I was on a 24-hour solo survival trip in the wilderness of the Canadian Rocky Mountains during my Canadian ‘survival and woods-travel instructor’ course at "De Voshaar”. At dawn I was cleaning up camp. I was standing close to a small river with a large hill on the other side of the water. The previous weeks we encountered many wild animals in the area, but what I experienced next I only dared to dream. While I was packing my sleeping bag, I heard a wolf started to howl behind the big hill. I howled back at the wolf once. It went quiet for a moment, but then the wolf howled again. Was this a coincidence or was it a response to my cry? Once again, I did my best wolf’s cry imitation and before I finished, the wolf cried back! Awesome! This gave me goosebumps! It continued for about twenty minutes with the wolf getting closer and closer. Suddenly it stopped and all went silent. Too bad, I thought, but thankful for the beautiful experience.<br><br>While packing my bags and breaking up camp a dog-like squeak came from opposite the water. This can’t be true, I thought. I started to run towards the river but quickly realised I had to adjust my pace not to frighten off the wolf. Full of excitement I creeped the last few meters from the forest to the river. Suddenly there it was, alone and majestic, about 50 meters away on the other side of the water. The wolf and I observed each other across the river. It raised its nose and moved its head up and down. Then he squeaked once more, turned around, and disappeared into the forest. A little later the wolf howled once more. Again, I responded. We howled back and forth, this time the distance between us growing larger and larger, until, eventually, I heard nothing…' +
                    '<br><br><b>Loup solitaire.</b>'
            },
            signup: {
                textBlock: {
                    title: 'Join!',
                    text: 'Does one of our courses appeal to you? Then sign up now! You can register multiple people at once. Please note their name, e-mailadres and phone number in the comments field. See you soon!'
                },
                basic: 'Beginners Survival Course',
                advanced: 'Advanced Survival Course',
                child: 'Ouder & kind survival weekend',
                till: 'till',
                form: {
                    title: 'Registration form',
                    name: 'Name',
                    email: 'E-mailadres',
                    phone: 'Phone number',
                    course: 'Course:',
                    available: 'When are you available:',
                    comments: 'Comments',
                    send: 'Send',
                    noCourse: 'Select a course',
                    success: 'Thank you for signing up, we will contact you with further information.',
                    sending: 'Sending...',
                    nodate1: 'If none of the dates suits you, please',
                    nodate2: 'us. We are very flexible!',
                    phoneEmailError: 'Please enter your email address or phone number, so we can contact you.'
                }
            },
            error: {
                send: "Something went wrong, please try again later",
            },
            footer: {
                phone: "Phone"
            },
            labels: {
                signup: "Sign up"
            }
        }
    }
});
