<template>
  <div id="app">
    <navbar></navbar>
    <div class="content-container">
      <router-view></router-view>
    </div>
    <foot></foot>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Arima+Madurai:100,200,300,400,500,700,800,900&display=swap');

@import 'scss/form';

#app {
  font-family: 'Avenir', sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #01504E;
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 8rem;
  height: 100%;
}

  .container {
    @media screen and(max-width: 1024px) {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }
  }

  .loup-button {
    background-color: #2B5F69;
    border: 0px;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    padding: 1rem 2rem;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  .loup-button:disabled {
    opacity: .5;
    cursor: wait;
  }

  .loup-button--filled {
    background-color: #2B5F69;
    border: 2px solid #2B5F69;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    color: white;
    font-size: 12px;
    font-weight: 900;
    padding: .5rem .5vw .5rem .5vw;
    cursor: pointer;

    @media screen and(max-width: 769px) {
      font-size: 1.5vmax;
      padding: .5rem 1rem .5rem 1rem;
    }

    &:hover {
      opacity: .8;
    }
  }

  .loup-button--outline {
    background-color: transparent;
    border: 2px solid #2B5F69;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    color: #2B5F69;
    font-weight: 900;
    font-size: 12px;
    padding: .5rem .5vw .5rem .5vw;
    cursor: pointer;

    @media screen and(max-width: 769px) {
      font-size: 1.5vmax;
      padding: .5rem 1rem .5rem 1rem;
    }

    &:hover {
      opacity: .8;
    }
  }

  a {
    text-decoration: none;
    color: #01504E !important;
  }

  .text-block--title {
    word-spacing: 300px;
    font-size: 3vmax;
    font-family: 'Arima Madurai', cursive;
    font-weight: 900;
    color: #01504E;
    line-height: 7vh;

    @media screen and(max-width: 769px) {
      word-spacing: unset;
      font-size: 40px;
      line-height: 40px;
    }
  }

  .text-block--text {
    font-size: 15px;
    font-family: 'Avenir', sans-serif;
    font-weight: 500;
    color: #01504E;
    line-height: 25px;

    @media screen and(max-width: 769px) {
      margin-top: .8rem;
      font-size: 15px;
      line-height: 20px;
    }
  }
</style>
<script>
  import Navbar from "./components/Navbar";
  import Foot from "./components/Foot";
  export default {
    components: {Foot, Navbar}
  }
</script>
