<template>
    <div class="container">
        <div class="columns navbar-wrapper">
            <div class="logo column is-2 is-3-mobile">
                <router-link :to="{path: '/'}"><img src="../assets/icons/logo2.svg" :alt="$t(`navbar.img.logo`)"></router-link>
            </div>
            <div class="column hamburger-wrapper is-2-mobile">
                <img src="../assets/icons/hamburger.svg" @click="toggleNav()" :alt="$t(`navbar.img.hamburger`)">
            </div>
            <div class="navbar column is-10 is-hidden-mobile">
                <router-link v-on:click.native="closeNav()" :to="{path: '/'}"><a class="navbar-item">{{ $t(`navbar.home`) }}</a></router-link>
                <router-link :to="{path: '/courses'}"><a class="navbar-item">{{ $t(`navbar.expeditions`) }}</a></router-link>
                <router-link :to="{path: '/about'}"><a class="navbar-item">{{ $t(`navbar.about`) }}</a></router-link>
                <router-link :to="{path: '/contact'}"><a class="navbar-item">{{ $t(`navbar.contact`) }}</a></router-link>
                <router-link :to="{path: '/signup'}" :aria-label="$t(`general.join`)"><button class="loup-button"><b>{{ $t(`general.join`) }}</b></button></router-link>
                <a><img :src="require(`../assets/icons/${possibleLang}.svg`)" class="navbar-item navbar-language" :alt="$t(`navbar.img.` + possibleLang)" @click="changeLanguage()"></a>
            </div>
            <div v-if="mobileNav" class="mobile-navbar is-hidden-desktop">
                <div class="mobile-navbar-close-wrapper">
                    <img src="../assets/icons/close-icon.svg" class="mobile-navbar-close" @click="toggleNav()" :alt="$t(`navbar.img.hamburger`)">
                </div>
                <ul class="mobile-nav-list">
                    <router-link :to="{path: '/'}"><li @click="navigate({path: '/signup'})" class="mobile-nav-list--item">{{ $t(`navbar.home`) }}</li></router-link>
                    <router-link :to="{path: '/courses'}"><li @click="navigate({path: '/signup'})" class="mobile-nav-list--item">{{ $t(`navbar.expeditions`) }}</li></router-link>
                    <router-link :to="{path: '/about'}"><li @click="navigate({path: '/signup'})" class="mobile-nav-list--item">{{ $t(`navbar.about`) }}</li></router-link>
                    <router-link :to="{path: '/contact'}"><li @click="navigate({path: '/signup'})" class="mobile-nav-list--item">{{ $t(`navbar.contact`) }}</li></router-link>
                    <li class="mobile-nav-list--item"><button @click="navigate({path: '/signup'})" class="loup-button"><b>{{ $t(`navbar.join`) }}</b></button></li>
                    <li class="mobile-nav-list--item"><img :src="require(`../assets/icons/${possibleLang}.svg`)" class="language-icon" :alt="$t(`navbar.img.` + possibleLang)" @click="changeLanguage()"></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Navbar",
        data: function () {
            return {
                mobileNav: false,
            }
        },
        methods: {
            toggleNav() {
                this.mobileNav = !this.mobileNav;
            },
            navigate(route) {
                this.closeNav()
                this.$router.push(route)
            },
            closeNav() {
                this.mobileNav = false;
            },
            changeLanguage() {
                if (this.$i18n.locale === 'nl') {
                    this.$i18n.locale = 'en'
                    this.$moment.locale('en')
                } else {
                    this.$i18n.locale = 'nl'
                    this.$moment.locale('nl')
                }
            },
        },
        computed: {
            possibleLang() {
                if (this.$i18n.locale === 'nl') {
                    return 'en'
                } else {
                    return 'nl'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .container {
        margin-top: 3rem;
        display: flex;

        @media screen and(max-width: 769px) {
            margin-top: 0;
            margin-bottom: 10rem;
        }
    }

    .logo {
        img {
            display: block;
            height: 18vh;

            @media screen and(max-width: 769px) {
                height: 10vh;
            }
        }
    }

    .columns {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @media screen and(max-width: 769px) {
            align-items: unset;
        }
    }

    @media screen and(max-width: 769px) {
        .navbar-wrapper {
            padding-top: 1rem;
            background-color: white;
            position: fixed;
            z-index: 5;
            display: flex;
            justify-content: space-between;
        }
    }

    .navbar {
        background-color: transparent;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;

        .navbar-item {
            padding: 3px;
            margin: 0.8rem 4vw 0 0;
            color: #01504E;
            font-family: 'Avenir', sans-serif;
            font-weight: 900;
            font-size: 20px;

            &:hover {
                background-color: transparent;
                opacity: .7;
            }
        }

        .navbar-language {
            height: 2.5rem;
            margin-top: 0;
            margin-left: 2rem;
            margin-right: 0;
        }

        .router-link-exact-active {
            .navbar-item {
                border-bottom: 3px solid #58B4CB;
                color: #01504E;
                background-color: transparent;
            }
        }
    }

    .hamburger-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media screen and(max-width: 769px) {
            padding-right: .37rem;
            margin-right: 2rem;

        }

        img {
            max-height: 3.5vh;
        }
    }

    .mobile-navbar {
        height: 100%;
        max-width: 100vw;
        width: 100%;
        position: fixed;
        background-color: #1F7F71;
        z-index: 4;
        top: 0;
        left: 0;

        .mobile-navbar-close-wrapper {
            z-index: 4;
            position: fixed;
            top: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;

            .mobile-navbar-close {
                padding: 1.5rem;
            }
        }

        .mobile-nav-list {
            top: 0;
            left: 0;
            position: fixed;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .router-link-exact-active {
                border: 0;
                position: relative;
                display: flex;
                justify-content: center;

                &:before {
                    content: "";
                    position: absolute;
                    width: 30%;
                    border-bottom: 3px solid #58B4CB;
                    bottom: 2vh;
                }
            }

            .mobile-nav-list--item {
                padding: 4vh;
                text-align: center;
                display: block;
                cursor: pointer;
                color: #ffffff;
                font-family: 'Avenir', sans-serif;
                font-weight: 900;
                font-size: 18px;

                .language-icon {
                    height: 2rem;
                }
            }
        }
    }
</style>
