import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/courses',
      name: 'courses',
      component: () => import(/* webpackChunckName: "expeditions" */ './views/Expeditions')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunckName: "about" */ './views/About')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunckName: "contact" */ './views/Contact')
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import(/* webpackChunckName: "signup" */ './views/SignUp')
    },
    {
      path: '*',
      redirect: {name: 'home'}
    }
  ]
})
