import moment from 'moment';
import 'moment/locale/nl'
import 'moment/locale/en-gb'

export default {
    install(Vue, name = '$moment') {
        moment.locale('nl');
        Object.defineProperty(Vue.prototype, name, { value: moment });
    },
};
