<template>
    <div class="container">
        <div class="is-12">
            <ul class="footer-list">
                <li class="footer-list--item"><a href="mailto:info@loupsolitaire.be">info@loupsolitaire.be</a></li>
                <li class="footer-list--item">BTW nr: BE 0734.972.661</li>
                <li class="footer-list--item">{{ $t(`footer.phone`) }}: <a href="tel:‎+3284490075">‎+32 84 49 00 75</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Foot"
    }
</script>

<style scoped lang="scss">
    .container {
        border-top: 3px solid #58b4cb;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .footer-list {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 2rem 0 2rem 0;
        flex-wrap: wrap;

        @media screen and(max-width: 769px) {
            justify-content: space-between;
        }
    }
</style>
